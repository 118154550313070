.App {
  text-align: flex-start;
  background-image: url("../images/texture.ca.png");
}

@font-face {
  font-family: 'Inter-Medium';
  src: url('../fonts/Inter-Medium.otf');
}

@font-face {
  font-family: 'Inter-Light';
  src: url('../fonts/Inter-Light.otf');
}

img {
  max-width: 100%;
}

.ultimate-logo {
  width: 200px;
  height: 65px;
  margin: 0px 0px 6.25vh 110px;
}

.ultimate-logo-mobile {
  width: 200px;
  height: 65px;
  margin: 0px 0px 6.25vh 0px;
}

.main-title {
  margin: 4vh 0px -1vh 130px;
  font-size: 65px;
  line-height: 90px;
  text-underline-offset: 20px;
}

.main-title-mobile {
  margin: 4vh 0px -1vh 10px;
  text-underline-offset: 10px;
}

a:hover {
  color: #FB4F14 !important;
}

.icons {
  color: gray;
  display: 'inline-block'
}

.icons:hover {
  color: #FB4F14 !important;
}

h1 {
  margin-bottom: 0px;
  font-weight: 700;
  font-family: 'Inter-Medium';
  color: #FB4F14;
}

h1 mark {
  background: linear-gradient(to bottom, #FB4F14 0%, #FB4F14 8%, #fff 8.5%, #fff 100%);
  color: #FB4F14;
  text-decoration: underline;
}

.ub-man {
  vertical-align: baseline;
  width: 200px;
}

.main-content {
  margin-left: 100px;
  display: inline-block;
  width: 30%;
  padding: 10px;
}

.main-content-mobile {
  display: inline-block;
  width: 30%;
  padding: 10px;
}

.main-functions {
  display: inline-block;
  font-family: 'Inter-Light';
  width: 45%;
  padding: 10px;
}

.main-functions-mobile {
  font-family: 'Inter-Light';
  width: 100%;
  padding: 10px;
}

h4 {
  color: #FB4F14;
  font-family: 'Inter-Light';
}
